<template>
  <div
    class="task-detail-right"
  >
    {{ rerender }}
    <div class="card">
      <div class="card-header">
        <h5>{{ $t('singleMessage') }}</h5>
      </div>
      <div class="card-block task-details">
        <div v-if="!isSenderNamesExists">
          <alert type="info" text="warnings.no-sender-name" class="mb-2">
            <div class="mt-2">
              <button class="btn btn-primary btn-sm mt-md-0" @click="goToPurchase">{{ $t('makePurchase') }}</button>
            </div>
          </alert>
        </div>
        <single-message-form
          v-if="license"
          :schema="singleFormSchema"
          :submit-button="singleSubmitButton"
          :initial-field-value="singleInitialValue"
          :clean-field-value="cleanFieldValue"
          :submit="singleSubmit"
          :onChangeHandler="onChangeHandler"
          :resetButton="resetButton"
          :needPrevalidation="needPrevalidation"
          :reset-disabled="resetDisabled"
        ></single-message-form>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineAsyncComponent } from 'vue'
import { useRouter } from 'vue-router'
import { useSingleMessageCreate } from '@/application/composables/singleMessageCreate.js'
import { store } from '@/application/store'

export default {
  name: 'MessagesFormWrapper',
  components: {
    'single-message-form': defineAsyncComponent(() => import('@/application/components/forms/SingleMessageForm.vue')),
    alert: defineAsyncComponent(() => import('@/application/components/elements/Alert.vue')),
  },
  props: {
    license: {
      type: Object,
      required: true,
    },
    rerender: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter()
    const {
      formSchema: singleFormSchema,
      submitButton: singleSubmitButton,
      resetButton,
      submit: singleSubmit,
      initialFieldValue: singleInitialValue,
      cleanFieldValue,
      onChangeHandler,
      needPrevalidation,
    } = useSingleMessageCreate(props.license)
    const resetDisabled = computed(() => {
      const data = store.getters.getSingleFormCache(props.license)
      return !data || Object.values(data).filter(item => !!item).length === 0
    })
    const isSenderNamesExists = computed(() => {
      return props.license.sender_names && props.license.sender_names.length > 0
    })

    return {
      resetDisabled,
      singleFormSchema,
      singleSubmitButton,
      singleSubmit,
      resetButton,
      singleInitialValue,
      onChangeHandler,
      cleanFieldValue,
      needPrevalidation,
      isSenderNamesExists,
      goToPurchase: () => {
        router.push({ name: 'purchase' })
      },
    }
  },
}
</script>
